<template>
  <b-card-code
    no-body
  >
    <b-card-body>
      <b-form @submit.prevent="searchRows()">
        <p v-if="companyId || companyName">
          Company: <a
            :href="`/company-management/summary/${companyId}`"
          >
            {{ companyName }}
          </a>
        </p>
        <b-form-row class="align-items-end">
          <b-col md="1">
            <feather-icon
              v-if="(searchFields && searchFields.length > 0) || (orderBy && orderBy.length > 0) || (sortedBy && sortedBy.length > 0)"
              v-b-tooltip.hover
              icon="XIcon"
              title="Reset filters"
              size="24"
              class="text-body align-middle mr-25"
              @click="resetFilter"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-card-body>
    <b-card-body>
      <!-- table -->
      <vue-good-table
        ref="VueGoodTable"
        :columns="fields"
        :rows="items"
        :total-rows="pagination.total"
        :is-loading="isLoading"
        compact-mode
        class="invite-table"
        mode="remote"
        :sort-options="{
          enabled: true,
          multipleColumns: false,
          initialSortBy: { field: orderBy, type: sortedBy }
        }"
        :pagination-options="{
          enabled: true,
          perPage: perPage,
          setCurrentPage: page
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: false,
          disableSelectInfo: false,
          selectionInfoClass: 'table-selected-row-info-panel',
        }"
        @on-sort-change="onSortChange"
        @on-column-filter="columnFilterFn"
      >
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-if="$can('create', 'user-invites')"
                  @click.prevent="resendInviteAction(props.row.id)"
                >
                  <feather-icon
                    icon="SendIcon"
                    class="mr-50"
                  />
                  <span>Resend Invite</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="$can('delete', 'user-invites')"
                  @click.prevent="cancelInviteAction(props.row.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Cancel Invite</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="perPage"
                :options="pageSizeOptions"
                class="mx-1"
                @change="perPageChanged"
              />
              <span class="text-nowrap"> of {{ pagination.total }} entries </span>
            </div>
            <div>
              <b-pagination
                v-if="pagination.last_page !== 1"
                :value="page"
                :total-rows="pagination.total"
                :per-page="perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="changePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-body>
  </b-card-code>
</template>
<script>
import {
  BCardBody,
  BCol, BDropdown, BDropdownItem,
  BForm,
  BFormRow,
  BFormSelect,
  BPagination, BSpinner,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import IndexPagesMixin from '@/mixins/IndexPages.vue'
import { ref, watch } from '@vue/composition-api'
import { mapActions, mapMutations, mapState } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BDropdownItem,
    BDropdown,
    BSpinner,
    BFormSelect,
    BPagination,
    BCardCode,
    BFormRow,
    BCardBody,
    BForm,
    BCol,
    VueGoodTable,
  },
  mixins: [
    IndexPagesMixin,
  ],
  data() {
    return {
      isLoading: true,
      fields: [
        {
          field: 'email',
          label: 'Email',
          filterOptions: {
            enabled: true,
            placeholder: 'Email',
            filterValue: '',
          },
        },
        {
          field: 'company.name',
          label: 'Company name',
          filterOptions: {
            enabled: true,
            placeholder: 'Company name',
            filterValue: '',
          },
        },
        {
          field: 'role.name',
          label: 'Role',
          filterOptions: {
            enabled: true,
            placeholder: 'Role',
            filterValue: '',
          },
        },
        {
          field: 'status',
          label: 'Status',
          filterOptions: {
            enabled: true,
            placeholder: 'Status',
            filterValue: '',
          },
        },
        {
          field: 'sender.name',
          label: 'Sent by',
          filterOptions: {
            enabled: false,
            placeholder: 'Sent by',
            filterValue: '',
          },
        },
        {
          field: 'created_at',
          label: 'Sent at',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'yyyy-mm-dd',
          filterOptions: {
            enabled: false,
            placeholder: 'Sent at',
            filterValue: '',
            filterFn: this.dateRangeFilter,
          },
        },
        {
          field: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'action-th-width',
          hidden: !this.$can('create', 'user-invites'),
        },
      ],
    }
  },
  setup() {
    const companyName = ref('')
    const companyId = ref('')

    watch(companyName, companyId, () => {
      if (this.$route.query.companyId) {
        const company = this.getCompany(this.$route.query.companyId)
        this.companyName = company.name
        this.companyId = this.$route.query.companyId
      } else {
        this.companyName = ''
      }
    })
    return {
      companyName: '',
    }
  },
  computed: {
    ...mapState('invite', {
      items: 'items',
      pagination: 'pagination',
      searchFields: 'searchFields',
      orderBy: 'orderBy',
      sortedBy: 'sortedBy',
      statuses: 'statuses',
      roles: 'roles',
    }),
    page() {
      return this.$route.query?.params || {}
    },
  },
  watch: {
    items() {
      if (this.items.length > 0) {
        this.fields = this.fields.map(item => {
          if (item.label === 'Status') {
            // eslint-disable-next-line no-param-reassign
            item = { ...item, filterOptions: { ...item.filterOptions, filterDropdownItems: [...this.statuses] } }
          }
          if (item.label === 'Role') {
            // eslint-disable-next-line no-param-reassign
            item = { ...item, filterOptions: { ...item.filterOptions, filterDropdownItems: [...this.roles] } }
          }
          return item
        })
      }
    },
  },
  async mounted() {
    if (this.$route.query.companyId) {
      const company = await this.getCompany(this.$route.query.companyId)
      this.companyName = company.name
    }
  },
  methods: {
    ...mapActions('userManagement', {
      getCompany: 'getCompany',
    }),
    ...mapActions('invite', {
      fetchItems: 'getItems',
      setSearchFields: 'setSearchFields',
      setOrderBy: 'setOrderBy',
      setSortedBy: 'setSortedBy',
      resendInvite: 'resendInvite',
      cancelInvite: 'cancelInvite',
    }),
    ...mapMutations('invite', ['RESET_STATE']),
    async changePage(pageNum) {
      if (this.$refs.VueGoodTable && this.$refs.VueGoodTable.selectedRow) {
        this.this.$refs.VueGoodTable.selectedRow = []
      }
      if (pageNum !== this.pagination.current_page) {
        const params = {
          pageNum,
          perPage: this.pagination.per_page,
          searchFields: this.searchFieldsString,
          search: this.searchString,
          orderBy: this.orderBy,
          sortedBy: this.sortedBy,
          searchJoin: 'and',
        }

        Object.keys(params).forEach(k => (params[k] === '' || !params[k]) && delete params[k])

        await this.fetchtableData(params)

        this.redirectByParams(params)
      }
    },
    /**
     * @params {number} currentPerPage
     */
    async perPageChanged(currentPerPage) {
      if (currentPerPage !== this.pagination.per_page) {
        await this.fetchtableData({ pageNum: 1, perPage: currentPerPage })
        const params = {
          pageNum: this.pagination.current_page,
          perPage: currentPerPage,
          searchFields: this.searchFieldsString,
          search: this.searchString,
          orderBy: this.orderBy,
          sortedBy: this.sortedBy,
          searchJoin: 'and',
        }

        Object.keys(params).forEach(k => (params[k] === '' || !params[k]) && delete params[k])

        this.redirectByParams(params)
      }
    },
    async searchRows() {
      const params = {
        pageNum: 1,
        perPage: this.perPage,
        searchFields: this.searchFieldsString,
        search: this.searchString,
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        searchJoin: 'and',
        companyId: this.companyId ?? 0,
      }

      Object.keys(params).forEach(k => (params[k] === '' || !params[k]) && delete params[k])
      await this.fetchtableData({
        ...params,
      })

      this.redirectByParams(params)
    },
    async onSortChange(sortEl) {
      if (this.startFilter) {
        return
      }
      const orderBy = sortEl[0].field
      const sortedBy = sortEl[0].type

      if (orderBy !== this.orderBy || sortedBy !== this.sortedBy) {
        const params = {
          pageNum: this.page,
          perPage: this.perPage,
          searchFields: this.searchFieldsString,
          search: this.searchString,
          orderBy,
          sortedBy,
          searchJoin: 'and',
        }

        Object.keys(params).forEach(k => (params[k] === '' || !params[k]) && delete params[k])
        this.startFilter = true
        await this.fetchtableData({
          ...params,
        })
        this.redirectByParams(params)
      }
    },
    redirectByParams(params) {
      setTimeout(() => {
        this.$router.push({
          name: this.$route.name,
          query: {
            ...params,
          },
        }).catch(() => {})
      }, 1)
    },
    dateRangeFilter(data, filterString) {
      const dateRange = filterString.split(',')
      const startDate = Date.parse(dateRange[0])
      const endDate = Date.parse(dateRange[1])
      // eslint-disable-next-line no-return-assign, no-param-reassign
      return (data = Date.parse(data) >= startDate && Date.parse(data) <= endDate)
    },
    async resendInviteAction(id) {
      try {
        await this.resendInvite(id)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })

        await this.fetchtableData({
          perPage: 1,
        })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    async cancelInviteAction(id) {
      try {
        await this.cancelInvite(id)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })

        await this.fetchtableData({
          perPage: 1,
        })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
